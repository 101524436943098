.file {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #e9e9e6;
  cursor: pointer;

  &Disabled {
    pointer-events: none;
  }


  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  > svg {
    max-width: 22px;
  }

  > span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}