.photo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 100%;
  }

  .personPhoto {
    object-fit: cover;
    object-position: center top;

    @media (max-width: 66rem) {
      object-fit: cover;
      object-position: center top;
    }
  }

  .placeHolder {
    object-fit: scale-down;
    object-position: center;
  }
}