.wrapper {
  height: 100%;
}

.button {
  position: relative;

  &Active {
    color: #491D8B !important;

    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: -1px;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: #ffffff;
    }

    .buttonInner .count {
      color: #ffffff;
      background-color: #8a3ffc;
    }
  }

  &Inner {
    position: relative;
    z-index: 8001;
    margin-bottom: -4px;

    .count {
      color: #491D8B;
      background-color: #ffffff;
      border-radius: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.675rem;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 0;

      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      padding: 0.2rem;
      min-width: 1rem;
      min-height: 1rem;
    }
  }
}

.notifContainer {
  max-height: 12.5rem;
  overflow-y: scroll;
}

.modalContainer {
  position: fixed;
  width: 30rem;
  max-width: 100%;
  top: 48px;
  right: 0;
  bottom: 0;
  background: white;
  border-left: 1px solid #e0e0e0;
  overflow: hidden;

  @media (max-width: 66rem) {
    width: 100%;
    border-left: 0;
  }
}

.item {
  margin-inline: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  &_grey {
    background-color: #e0e0e0;
  }

  svg {
    margin-top: 4px;
    min-width: max-content;
  }
}

.toolbar {
  background-color: white;
  justify-content: space-between;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;

  a {
    &:first-child {
      grid-column: 1;
    }

    &:last-child {
      grid-column: 2;
    }
  }
}
