.row {
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid #e9e9e6;
    padding-top: 10px;
  }
}

.subtitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.goal {
  & + & {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px dashed #e9e9e6;
  }
}