.wrapper {
  padding: 10px 0;
  min-height: 100%;
  overflow-y: auto;
}

.item {
  & + & {
    margin-top: 10px;
  }
}

.header {
  display: flex;
  margin-bottom: 5px;

  .author {
    font-weight: 700;
  }

  .date {
    margin-left: auto;
    padding-left: 10px;
    font-size: small;
    color: #8d8d8d;
  }
}

.content {
  border: 1px solid #e9e9e6;
  padding: 3px 5px;
  white-space: pre-line;
}