.wrapper {
  margin-top: 20px;

  .notFound {
    text-align: center;
  }

  .loader {
    padding: 15px 0;
    text-align: center;
  }
}