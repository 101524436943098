.card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #e9e9e6;
  border-radius: 7px;
  font-size: 14px;
  line-height: 1.3;
  background-color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  transition: background-color 250ms ease-in-out;

  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;

    > span {
      font-weight: normal;
    }
  }

  .hasChildren {
    margin-left: auto;
    padding-left: 20px;
    color: #8d8d8d;

    svg {
      position: relative;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }

  &:hover {
    background-color: #F4F4F4;

    .hasChildren {
      color: inherit;
    }
  }

  .subTitle {
    color: #8d8d8d;
    font-size: 13px;
  }
}
