.dropdown {
  position: relative;

  .button {
    display: flex;
    align-items: center;

    > div {
      display: inline-block;
      margin-right: 8px;
    }
  }
}

.buttonIcon {
  transition: transform .11s, fill .11s;
  min-width: 16px;

  .visible & {
    transform: rotate(180deg);
  }
}

.popup {
  display: none;
  position: absolute;
  top: 100%;
  min-width: 160px;
  background-color: var(--cds-layer);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .5);

  .visible & {
    display: block;
  }

  .left & {
    left: 0;
  }

  .right & {
    right: 0;
  }
}