.content {
  padding: 10px !important;

  :global(.cds--tag) {
    //display: block;
  }
}

.disabled {
  &, & * {
    cursor: default !important;
  }
}

.tag {
  white-space: nowrap;
}