.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;

  &Item {
    flex: 1 0 auto;
  }

  .multiSelect {
    min-width: 200px;
    max-width: 400px;
  }

  .filterableSelect {
    min-width: 200px;
    max-width: 400px;
    inline-size: auto;

    input {
      &::placeholder {
        color: #161616 !important;
      }
    }
  }

  .lastButton {
    margin-left: auto;
    flex: 0;

    @media (max-width: 42rem) {
      margin-left: initial;
    }
  }
}