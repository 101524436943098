.header {
  position: fixed;
  z-index: 8000;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100wv;
  height: 48px;
  padding: 0 0 0 30px;
  background-color: #8a3ffc;

  @media (max-width: 66rem) {
    padding-left: 15px;
  }
}

.logo {
  display: block;
  position: relative;
  height: 32px;
  margin-right: 15px;
  flex: 0 0 auto;
  //height: 24px; // for new logo.svg

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 25rem) {
    max-width: 32px;
    overflow: hidden;

    img {
      max-width: initial;
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.secondNav {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  padding-left: 20px;

  @media (max-width: 66rem) {
    padding-left: 0;
  }
}


.nav, .secondNav {
  .navLink {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    min-height: 100%;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;

    &:global(.active) {
      color: #ffffff;

      &:after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 3px;
        background-color: #491D8B;
      }
    }

    .secondLine {
      font-size: 12px;
      text-transform: lowercase;
      color: #E8DAFF;
    }
  }

  .subMenu {
    height: 100%;

    .navLink {
      min-height: 100%;
    }

    &Popup {
      .navLink {
        padding: 15px 16px;
        white-space: nowrap;
        color: #525252;

        &:hover {
          background-color: #F4F4F4;
        }

        &:global(.active) {
          font-weight: 700;
          color: #161616;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.burger {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  margin-left: -15px;
  cursor: pointer;
  color: #ffffff;
}

.mobileNav {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 0;
  background-color: #ffffff;
  top: 48px;
  right: 0;
  bottom: 0;
  left: 0;

  .navLink {
    position: relative;
    display: flex;
    padding: 10px 15px;
    text-decoration: none;
    color: #525252;
    cursor: pointer;

    transition: background 250ms ease-in-out;

    &:hover {
      background-color: #f4f4f4;
    }

    &:global(.active) {
      color: #161616;

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 3px;
        background-color: #8a3ffc;
      }
    }

    .secondLine {
      font-size: 12px;
      text-transform: lowercase;
      color: #757575;
    }
  }

  .subMenu {
    width: 100%;

    > .navLink {
      > svg {
        margin-left: auto;
      }
    }

    &Popup {
      position: relative;
      width: 100%;
      top: initial;
      left: initial;
      box-shadow: none;
      border-bottom: 1px solid #e0e0e0;

      .navLink {
        padding-left: 30px;

        &:hover {
          background-color: #F4F4F4;
        }

        &:global(.active) {
          font-weight: 700;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
