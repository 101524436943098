.modalBody {
  position: relative;
  flex: 1;
  padding: 0 30px;
  overscroll-behavior-y: contain;
  min-height: 200px;

  &:first-child {
    padding-top: 30px;
  }

  &:last-child {
    padding-bottom: 30px;
  }

  @media (max-width: 66rem) {
    padding: 0 15px;

    &:first-child {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }

  &Loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}