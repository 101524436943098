@use '@carbon/styles/scss/themes';
@use '@carbon/styles/scss/theme' with (
  $theme:
    map-merge(
      themes.$g10,
      (
        // see @carbon/styles/scss/__tests__/theme-test.js for the list of options available
        border-interactive: #8a3ffc,
        focus: #8a3ffc,
        layer-accent-01: #f4f4f4,
        field-01: #f4f4f4
      )
    )
);

@use '@carbon/styles/scss/components/button/tokens' with (
  $button-primary: #8a3ffc,
  $button-primary-hover: #8a3ffc,
  $button-tertiary: #8a3ffc,
  $button-tertiary-hover: #8a3ffc
);
@use '@carbon/react';

@use '@carbon/react/scss/grid/flexbox';

@import '~@assets/styles/normalize';
@import '~@assets/styles/normalizeCustom';

// Прочие импорты
@import '~@assets/styles/grid';

//
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

#root {
  height: 100%;
}

* {
  outline: none !important;
}

.a {
  cursor: pointer;
  color: var(--cds-link-primary, #0062fe);
  text-decoration: underline;
}

// Базовые глобальные стили
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-muted {
  color: #8d8d8d;
}

.text-700 {
  font-weight: bold;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-0 {
  margin-right: 0;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-0 {
  margin-left: 0;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.full-height-select {
  .cds--select {
    height: 100%;
  }

  .cds--select-input__wrapper {
    height: 100%;
  }

  .cds--select-input {
    height: 100%;
  }

  .cds--form-requirement {
    display: none !important;
  }
}

.customCds {
  .cds--toolbar-content {
    clip-path: initial !important;
  }

  .cds--search-input {
    border-block-end: initial !important;
  }

  .cds--table-header-label {
    white-space: nowrap;
  }
}

.table-without-hover {
  .cds--data-table tbody tr:not([data-child-row]):hover,
  .cds--data-table tbody tr[data-child-row]:hover > td {
    background-color: transparent;
  }

  .cds--data-table tbody tr:hover td, .cds--data-table tbody tr:hover th {
    border-block-end-color: var(--cds-border-subtle);
    border-block-start-color: var(--cds-layer);
    color: var(--cds-text-secondary, #525252);
  }
}

.content-box {
  background-color: #ffffff;
  padding: 20px;
}

.cds--data-table th.action-column {
  inline-size: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-display {
  display: none;
}

.pagination {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: var(--cds-layer);
}

.cds--file__selected-file {
  background-color: var(--cds-layer-accent);
  max-inline-size: initial;
}

.multiselect-filter--without-tag {
  .cds--tag--filter {
    margin: 0 0 0 1rem !important;
    padding: 0 !important;
    width: 0 !important;
    opacity: 0;

    * {
      display: none !important;
    }
  }
}
