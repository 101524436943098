.modalBackButton {
  position: absolute;
  left: 10px;
  top: 10px;

  padding: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: url('~@library/utils/modals/assets/back.svg') no-repeat center;

  &:focus,
  &:active {
    outline: none;
  }

  @media (min-width: 66rem) {
    opacity: 0.25;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}