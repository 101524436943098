.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.52);
  opacity: 0;
  transition: opacity 300ms;

  &Active {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 66rem) {
    padding-left: 0;
    padding-right: 0;
  }
}

.modalDialog {
  position: relative;
  width: auto;
  margin: 0;

  @media (min-width: 66rem) {
    margin: 60px auto;
    min-height: calc(100% - 60px * 2);
  }

  @media (min-width: 66rem) and (max-height: 1000px) {
    margin-top: 40px;
    margin-bottom: 40px;

    min-height: calc(100% - 40px * 2);
  }

  @media (min-width: 66rem) and (max-height: 800px) {
    margin-top: 24px;
    margin-bottom: 24px;

    min-height: calc(100% - 24px * 2);
  }

  @media (max-width: 66rem) {
    height: 100%;
  }

  &sm {
    @media (min-width: 66rem) {
      max-width: 320px;
    }
  }

  &md {
    @media (min-width: 66rem) {
      max-width: 520px;
    }
  }

  &lg {
    @media (min-width: 66rem) {
      max-width: 700px;
    }
  }

  &xl {
    @media (min-width: 66rem) {
      max-width: 970px;
    }
  }

  &xxl {
    @media (min-width: 66rem) {
      max-width: 1200px;
    }
  }

  &Centered {
    @media (min-width: 66rem) {
      display: flex;
      align-items: center;
    }
  }

  &Scrollable {
    height: calc(100% - 60px * 2);

    @media (max-height: 1000px) {
      height: calc(100% - 40px * 2);
    }

    @media (max-height: 800px) {
      height: calc(100% - 24px * 2);
    }

    @media (max-width: 66rem) {
      height: 100%;
    }

    :global {
      .modal-content {
        max-height: 100%;
        overflow: hidden;
      }

      .modal-body {
        max-height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  border-radius: 0;

  @media (max-width: 66rem) {
    border-radius: 0;
    min-height: 100%;
  }

  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateY(-50px);

  @media (max-width: 66rem) {
    transform: translateY(100px);
  }

  &Active {
    opacity: 1;
    transform: translateY(0px);
  }
}


